









































































import { Observer } from 'mobx-vue'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import draggable from 'vuedraggable'

@Observer
@Component({
  components: {
    draggable,
  },
})
export default class extends Vue {
  opstions = [1]
  @Prop({
    default: () => {
      return {}
    },
  })
  controller

  @Ref('add-social-selector') addSocialSelector
  @Prop({ default: false }) notEditable

  get heightInput() {
    return this.$vuetify.breakpoint.smOnly ? 44 : 50
  }

  preventNegativeNumber(event) {
    if ((event.charCode != 8 && event.charCode == 0) || (event.charCode >= 48 && event.charCode <= 57)) return true
    else event.preventDefault()
  }

  addSocial(value) {
    setTimeout(() => {
      this.controller.addSocial(value)
      this.addSocialSelector.blur()
    }, 100)
  }
}
