var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column-gap-4 primary darken-2",class:_vm.$vuetify.breakpoint.smOnly ? '' : 'pa-4'},[_vm._l((_vm.controller.socialInfos),function(social){return _c('div',{key:social.name,staticClass:"d-flex gap-2"},[_c('div',{staticClass:"social-name-space row-gap-2 align-center",style:(("height: " + _vm.heightInput + "px"))},[_c('v-img',{attrs:{"src":social.url,"max-width":"28","width":"28","max-height":"28","contain":""}})],1),_c('div',{staticClass:"d-flex gap-2 table-row flex-fill overflow-hidden",class:{
        'flex-column': _vm.$vuetify.breakpoint.smOnly,
      }},[_c('div',{staticClass:"flex-fill"},[_c('v-text-field-custom',{staticClass:"text-sm-body2 text-body5 input-validator",attrs:{"background-color":"primary darken-1","height":_vm.heightInput,"placeholder":((social.name) + " link"),"disabled":_vm.notEditable,"append-icon":"mdi-link"},model:{value:(social.link),callback:function ($$v) {_vm.$set(social, "link", $$v)},expression:"social.link"}})],1),_c('div',{style:(_vm.$vuetify.breakpoint.smOnly ? '' : 'max-width: 200px; min-width: 80px')},[_c('v-text-field-custom',{staticClass:"text-sm-body2 text-body5 input-validator",attrs:{"background-color":"primary darken-1","height":_vm.heightInput,"placeholder":"Follower number","disabled":_vm.notEditable},on:{"keypress":function($event){return _vm.preventNegativeNumber($event)}},model:{value:(social.followerNumber),callback:function ($$v) {_vm.$set(social, "followerNumber", $$v)},expression:"social.followerNumber"}})],1)]),_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.controller.removeSocial(social.uniqName)}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-minus-circle")])],1)],1)])}),_c('v-select-custom',{ref:"add-social-selector",staticClass:"text-sm-body2 text-body5 align-self-end",staticStyle:{"width":"200px","border":"1px solid white"},attrs:{"dense":"","background-color":"primary darken-2","menu-props":{
      contentClass: 'selector-recruitment ',
      offsetY: true,
    },"label":"Add more social","rounded":"","hide-details":"","height":40,"flat":"","solo":"","item-value":"uniqName","item-text":"name","items":_vm.controller.socialSelectors},on:{"change":function($event){return _vm.addSocial($event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"row-gap-2 align-center"},[_c('v-img',{attrs:{"src":item.url,"max-width":"20","width":"20","max-height":"20","contain":""}}),_vm._v(" "+_vm._s(item.name)+" ")],1)]}}]),model:{value:(_vm.controller.selectedSocial),callback:function ($$v) {_vm.$set(_vm.controller, "selectedSocial", $$v)},expression:"controller.selectedSocial"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }